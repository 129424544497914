<template>
  <div>
    <v-app class="bg-transparent">
      <!-- ---------------LOADER PROCESANDO DATOS ---------------- -->
      <!-- <div v-if="cargando">
        <loader
          object="#4caf50"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="40"
          disableScrolling="false"
          name="box"
        ></loader>
      </div> -->

      <div>
        <v-row justify="center">
          <!-- Modal para Crear -->
          <v-dialog
            @click:outside="resetTelCoCorreo()"
            v-model="dialog"
            max-width="600px"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <span class="text-h5">Notificaciones TelCo</span>
                <span class="display flex justify-end"
                  ><v-btn
                    text
                    fab
                    small
                    @click="(dialog = false), resetTelCoCorreo()"
                  >
                    <v-icon>mdi-close</v-icon></v-btn
                  ></span
                >
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="email"
                        label="Email*"
                        :rules="reglaEmail"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="checkCobranzas"
                        class="ckeck"
                        label="Cobranzas"
                        >Cobranzas</v-checkbox
                      >
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="checkRendiciones"
                        class="ckeck"
                        label="Rendiciones"
                        >Rendiciones</v-checkbox
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <div>
                  <v-btn
                    text
                    color="red"
                    :loading="cargandobtn"
                    @click="(dialog = false), resetTelCoCorreo()"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    :loading="cargandobtn"
                    @click="save(), (dialog = false)"
                  >
                    Guardar
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- cargando -->
          <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                Guardando
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </v-app>
    <v-app>
      <v-main>
        <!-- SHOW ERROR SECTION -->
        <!-- <section v-if="errored">
            <v-alert
              border="left"
              class="mt-3 mx-3 elevation-3"
              color="red lighten-2"
              dark
            >
              Lo sentimos, no es posible obtener la información en este
              momento, por favor intente nuevamente mas tarde. Volver a
              intentar
            </v-alert>
          </section> -->
        <template>
          <v-card flat>
            <v-row no-gutters>
              <!-- FILTRO BUSCAR -->
              <v-col class="flex justify-start mt-3 mb-5">
                <v-row class="d-flex align-center">
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="searchByEmail"
                      append-icon="find_in_page"
                      label="Buscar por email"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto"> </v-col>
                </v-row>
              </v-col>

              <!-- BOTON DE CREAR NUEVO -->
              <v-col cols="6" justify="center">
                <div class="flex justify-end ">
                  <v-btn
                    class="text-white"
                    style="background: #50B565;"
                    @click="dialog = true"
                  >
                    Nueva
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <v-skeleton-loader
              :loading="cargando"
              type="table"
              height="calc(100vh-300px)"
            >
              <v-data-table
                :headers="headers"
                :items="telcocorreos"
                :items-per-page="itemsPerPage"
                :loading="cargando"
                :page.sync="page"
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                item-key="Id"
                ref="tabla_telcocorreo"
                calculate-widths
                hide-overlay
                hide-default-footer
                disable-sort
                class="elevation-0 trow"
                default-footer
                @page-count="pageCount = $event"
              >
                <!-- ITEM CHECK COBRANZAS -->
                <template v-slot:[`item.cobrazas`]="{ item }">
                  <v-checkbox
                    hide-details
                    class="ckeck mt-0 mb-0"
                    v-model="item.cobrazas"
                    :value="item.cobrazas"
                    @change="cambiarCheck(item)"
                  >
                  </v-checkbox>
                </template>

                <!-- ITEM CHECK RENDICIONES -->
                <template v-slot:[`item.rendiciones`]="{ item }">
                  <v-checkbox
                    hide-details
                    class="ckeck mt-0 mb-0"
                    v-model="item.rendiciones"
                    :value="item.rendiciones"
                    @change="cambiarCheck(item)"
                  >
                  </v-checkbox>
                </template>

                <!-- cantidad de registros por pagina -> actualizar numero de registros por pagina -->
                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ itemsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
              </v-data-table>
            </v-skeleton-loader>
            <!-- END DATA TABLE -->
            <!-- Paginacion -->
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
          </v-card>
        </template>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "TelCoCorreo",
  data() {
    return {
      email: "",
      cargandobtn: false,
      dialog: false,
      dialogLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loading: false,
      searchByEmail: null,
      mostrarRegla: false,
      headers: [
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        { text: "Cobranzas", sorteble: false, value: "cobrazas" },
        {
          text: "Rendiciones fuera de Termino",
          sorteble: false,
          value: "rendiciones",
        },
      ],

      checkCobranzas: "",
      checkRendiciones: "",
      //  numero de registros por pagina
      rowsPerPageArray: [10, 40, 100],
      editedIndex: -1,
      footerProps: {
        "items-per-page-options": [5, 10, 20, 30],
        "items-per-page-text": "Mostrar",
        "prev-icon": "",
        "next-icon": "",
        "page-text": "{0}-{1} de {2}",
      },
    };
  },
  created() {
    this.getTelCoCorreos();
  },
  computed: {
    //Estados de TelCo-Correo Store
    ...mapState("telcocorreos", [
      "telcocorreos",
      "total",
      "visible",
      "rowsPerPage",
      "cargando",
      "errored",
    ]),

    //Regla de campo vacio
    reglaEmail() {
      return this.mostrarRegla
        ? [(v) => !!v || "Este campo no puede estar vacio"]
        : [];
    },
    paginaActual: {
      get() {
        return this.$store.getters["telcocorreos/pages"];
      },
    },
    existenTelCoCorreos() {
      return this.telcocorreos.length > 0;
    },
  },

  watch: {
    searchByEmail(newValue) {
      // console.log("watch email");
      const query = newValue;
      this.$store.commit("telcocorreos/setEmail", query);
      this.getTelCoCorreos();
    },
  },

  methods: {
    ...mapActions("telcocorreos", [
      "getTelCoCorreos",
      "createCorreoTelCo",
      "editarTelCoCorreos",
    ]),

    resetTelCoCorreo() {
      this.email = "";
      this.checkCobranzas = false;
      this.checkRendiciones = false;
      this.mostrarRegla = false;
    },

    //Crear Correo
    async save() {
      try {
        // Verificar si la consulta está vacía
        if (!this.email.trim()) {
          // Si la consulta está vacía, muestra un mensaje de error o realiza alguna acción adecuada
          // console.error("El campo de Email no puede estar vacía");
          this.$toastr.e("El campo de Email no puede estar vacía");
          this.mostrarRegla = true;
          return;
        }
        const formData = new FormData();
        formData.append("email", this.email);
        formData.append("cobranzas", this.checkCobranzas);
        formData.append("rendiciones", this.checkRendiciones);
        this.cargandobtn = true;
        (this.dialogLoading = true), await this.createCorreoTelCo(formData);
        (this.dialogLoading = false), this.$toastr.s("Guardado!");
        this.cargandobtn = false;
        // this.dialog = false;
        this.getTelCoCorreos();
        this.resetTelCoCorreo();
      } catch (error) {
        this.resetTelCoCorreo(),
          (this.dialogLoading = false),
          (this.cargandobtn = false);
        // Manejar cualquier error que ocurra durante la creación del correo
        this.$toastr.e(error.response.data.message);
        // console.log("Error creando Correo Vista:", error);
      }
    },

    async cambiarCheck(item) {
      try {
        const rendicionesValue = item.rendiciones ? true : false;
        const cobranzasValue = item.cobrazas ? true : false;
        const formData = new FormData();
        formData.append("id", item.id);
        formData.append("email", item.email);
        formData.append("cobranzas", cobranzasValue);
        formData.append("rendiciones", rendicionesValue);
        await this.editarTelCoCorreos(formData);
        this.$toastr.s("Editado!");
        this.getTelCoCorreos(); // Espera a que la llamada se complete antes de continuar
      } catch (error) {
        this.$toastr.e("Error Editando!");
        // console.error("Error editando Correo Vista:", error);
      }
    },

    // paginar datatable
    onPageChange(event) {
      this.$store.commit("telcocorreos/setPaginate", event);
      this.getTelCoCorreos();
    },
    // paginacion mediante back usando store
    // registros por paginas (actualizar numero de registros por pagina) por si se cambia a
    // updateRowsPerPage(number) {
    //   this.$store.commit("telcocorreos/setrowsPerPage", number);
    //   this.$store.commit("telcocorreos/setPaginate", 1);
    //   this.getTelCoCorreos();
    // },

    // Paginacion mediante front
    updateRowsPerPage(number) {
      this.itemsPerPage = number;
      // Ajustar la página actual si está fuera del rango
      if (this.page > this.pageCount) {
        this.page = this.pageCount;
      }
      this.$store.commit("telcocorreos/setrowsPerPage", number);
      this.$store.commit("telcocorreos/setPaginate", 1);
      //    // Cargar datos correspondientes a la nueva página y cantidad de elementos mostrados por página
      this.getTelCoCorreos();
    },
  },
};
</script>
